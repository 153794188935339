import { useEffect, useState, useRef } from 'react';
import Anime, { anime } from 'react-anime';
import './SplashScreen.css'
import $ from 'jquery';
import useOnScreen from '../../hooks/useOnScreen';


function SplashScreen({ setShowingSplashScreen }) {
  const animationRef = useRef(null);
  const animationRefTwo = useRef(null)
  const [showSecondRow, setShowSecondRow] = useState(false)
  const [count, setCount] = useState(0)

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  const delay = 5.2;

  useEffect(() => {
    console.log("count is", count)
    console.log("visible is", isVisible)
    if (!isVisible){
      setCount(count + 1)
    }  
    if (isVisible == false && count == 1) {
      setShowingSplashScreen(false);
      $("html").css("overflow-y", "auto")
    } else if (count == 1 && isVisible == true){
      // this is sort of a failsafe for some browers (like firefox)
      setTimeout(function() {
        setShowingSplashScreen(false)
      }, 5000)
    }
  }, [isVisible])


  return (
    <div>
      <div id='splash-screen'>
        <div translate="no">
          <div className='wrapper'>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i ref={ref}></i>
          </div>
          <a href='#black' id='black'></a>

        </div>
      </div>
    </div>
  )
}

export default SplashScreen;