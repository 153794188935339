import React, { useState } from 'react';

import '../App.css'

import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

function SimpleAccordion({ titleContent, bodyContent }) {

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  return (
    <div className="App">

      <Accordion className = "accordion-class" expanded={expandedPanel === 'panel1'} onChange={handleAccordionChange('panel1')}>

        <AccordionSummary expandIcon={<ExpandMore />}>

          <span className = "faq-title">{titleContent}</span>
          
  
        </AccordionSummary>

        <AccordionDetails>
          <span className = 'faq-body'>
            {bodyContent}
          </span>
        </AccordionDetails>


      </Accordion>

    </div>
  );
}

export default SimpleAccordion;
// Owning a Cozy Companion will also allow you to play in the upcoming Cozy Companion PVP game. 