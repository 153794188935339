import { useEffect, useState, useRef, Component } from "react";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import { anime } from "react-anime";
import mySvg from "./images/person.svg";
import diceGuy from "./images/diceGuy.png";
import armatillz from "./images/armatillz.webp";
import website_cozy from "./images/website_cozy.png";
import islandOne from "./images/islandOne.webp";
import islandTwo from "./images/islandTwo.webp";
import islandThree from "./images/islandThree.webp";
import cloudOne from "./images/cloudOne.webp";
import cloudTwo from "./images/cloudTwo.webp";
import cloudThree from "./images/cloudThree.webp";
import plutoBaggins from "./images/plutoBaggins.webp";
import propeller from "./images/propeller.webp";
import "./App.css";
import $ from "jquery";
import {
  FaClipboardList,
  FaDice,
  FaPaw,
  FaDog,
  FaRegGem,
  FaHeart,
  FaMobileAlt,
  FaGlobe,
} from "react-icons/fa";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import SimpleAccordion from "./components/Accordion";

function App() {
  const animationRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [inHover, setHover] = useState(false);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
    let middleIsland = $(".large-island");
    let waterfall = $("#waterfall");
    let middleIslandWidth = middleIsland.width();
    let middleIslandHeight = middleIsland.height();
    waterfall.css({
      top: middleIslandHeight * 0.4,
      left: middleIslandWidth * 0.9,
      height: middleIslandHeight * 0.6,
    });
  };

  // adapted from https://codepen.io/nrmarston/pen/DWNbKz
  var waterfallCanvas = function (c, cw, ch) {
    var path = [0, 0, 100, 20, 100, 100, 20, 100];
    var _this = this;
    this.c = c;
    this.ctx = c.getContext("2d");
    this.ctx.beginPath();
    this.ctx.moveTo((path[0] * cw) / 100, (path[1] * ch) / 100);
    for (var i = 2; i < path.length; i += 2) {
      this.ctx.lineTo((path[i] * cw) / 100, (path[i + 1] * ch) / 100);
    }
    this.ctx.closePath();
    this.ctx.clip();
    this.cw = cw;
    this.ch = ch;

    this.particles = [];
    this.particleRate = 6;
    this.gravity = 0.15;

    this.init = function () {
      this.loop();
    };

    this.reset = function () {
      this.ctx.clearRect(0, 0, this.cw, this.ch);
      this.particles = [];
    };

    this.rand = function (rMi, rMa) {
      return ~~(Math.random() * (rMa - rMi + 1) + rMi);
    };

    this.Particle = function () {
      var newWidth = _this.rand(1, 20);
      var newHeight = _this.rand(1, 45);
      this.x = _this.rand(10 + newWidth / 2, _this.cw - 10 - newWidth / 2);
      this.y = -newHeight;
      this.vx = 0;
      this.vy = 0;
      this.width = newWidth;
      this.height = newHeight;
      this.hue = _this.rand(200, 220);
      this.saturation = _this.rand(30, 60);
      this.lightness = _this.rand(30, 60);
    };

    this.Particle.prototype.update = function (i) {
      this.vx += this.vx;
      this.vy += _this.gravity;
      this.x += this.vx;
      this.y += this.vy;
    };

    this.Particle.prototype.render = function () {
      _this.ctx.strokeStyle =
        "hsla(" +
        this.hue +
        ", " +
        this.saturation +
        "%, " +
        this.lightness +
        "%, .02)";
      _this.ctx.beginPath();
      _this.ctx.moveTo(this.x, this.y);
      _this.ctx.lineTo(this.x, this.y + this.height);
      _this.ctx.lineWidth = this.width / 2;
      _this.ctx.lineCap = "round";
      _this.ctx.stroke();
    };

    this.Particle.prototype.renderBubble = function () {
      _this.ctx.fillStyle = "hsla(" + this.hue + ", 40%, 40%, 1)";
      _this.ctx.fillStyle =
        "hsla(" +
        this.hue +
        ", " +
        this.saturation +
        "%, " +
        this.lightness +
        "%, .3)";
      _this.ctx.beginPath();
      _this.ctx.arc(
        this.x + this.width / 2,
        _this.ch - 20 - _this.rand(0, 10),
        _this.rand(1, 8),
        0,
        Math.PI * 2,
        false
      );
      _this.ctx.fill();
    };

    this.createParticles = function () {
      var i = this.particleRate;
      while (i--) {
        this.particles.push(new this.Particle());
      }
    };

    this.removeParticles = function () {
      var i = this.particleRate;
      while (i--) {
        var p = this.particles[i];
        if (p.y > _this.ch - 20 - p.height) {
          p.renderBubble();
          _this.particles.splice(i, 1);
        }
      }
    };

    this.updateParticles = function () {
      var i = this.particles.length;
      while (i--) {
        var p = this.particles[i];
        p.update(i);
      }
    };

    this.renderParticles = function () {
      var i = this.particles.length;
      while (i--) {
        var p = this.particles[i];
        p.render();
      }
    };

    this.clearCanvas = function () {
      this.ctx.globalCompositeOperation = "destination-out";
      this.ctx.fillStyle = "rgba(255,255,255,.06)";
      this.ctx.fillRect(0, 0, this.cw, this.ch);
      this.ctx.globalCompositeOperation = "lighter";
    };

    this.loop = function () {
      var loopIt = function () {
        requestAnimationFrame(loopIt, _this.c);
        _this.clearCanvas();
        _this.createParticles();
        _this.updateParticles();
        _this.renderParticles();
        _this.removeParticles();
      };
      loopIt();
    };
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  var isCanvasSupported = function () {
    var elem = document.createElement("canvas");
    return !!(elem.getContext && elem.getContext("2d"));
  };

  var setupRAF = function () {
    var lastTime = 0;
    var vendors = ["ms", "moz", "webkit", "o"];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame =
        window[vendors[x] + "RequestAnimationFrame"];
      window.cancelAnimationFrame =
        window[vendors[x] + "CancelAnimationFrame"] ||
        window[vendors[x] + "CancelRequestAnimationFrame"];
    }

    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = function (callback, element) {
        var currTime = new Date().getTime();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));
        var id = window.setTimeout(function () {
          callback(currTime + timeToCall);
        }, timeToCall);
        lastTime = currTime + timeToCall;
        return id;
      };
    }

    if (!window.cancelAnimationFrame) {
      window.cancelAnimationFrame = function (id) {
        clearTimeout(id);
      };
    }
  };

  const getImageInfo = (event) => {
    const imageHeight = event.target.clientHeight;
    const imageWidth = event.target.clientWidth;
    let middleIsland = $(".large-island");
    let waterfall = $("#waterfall");
    let middleIslandWidth = middleIsland.width();
    let middleIslandHeight = middleIsland.height();
    waterfall.css({
      top: middleIslandHeight * 0.4,
      left: middleIslandWidth * 0.9,
      height: middleIslandHeight * 0.6,
    });
    startWaterfall();
  };

  const [showingSplashScreen, setShowingSplashScreen] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(count + 1);
    animationRef.current = anime({
      targets: ".description-anim",
      opacity: [0, 1],
      complete: function (anim) {},
      duration: 1000,
      easing: "easeInOutCirc",
    });
    if (count >= 1) {
      $("#about-nav").click(function (event) {
        event.preventDefault();
        $("html, body").animate(
          {
            scrollTop: $("#about-section").offset().top,
          },
          100
        );
      });
      $("#roadmap-nav").click(function (event) {
        event.preventDefault();
        $("html, body").animate(
          {
            scrollTop: $("#roadmap-section").offset().top,
          },
          300
        );
      });
      $("#faq-nav").click(function (event) {
        event.preventDefault();
        $("html, body").animate(
          {
            scrollTop: $("#faq-section").offset().top,
          },
          500
        );
      });
      $("#team-nav").click(function (event) {
        event.preventDefault();
        $("html, body").animate(
          {
            scrollTop: $("#team-section").offset().top,
          },
          700
        );
      });
    }
  }, [showingSplashScreen]);

  function startWaterfall() {
    if (isCanvasSupported()) {
      var c = document.getElementById("waterfall");
      var cw = (c.width = 60);
      var ch = (c.height = 200);
      var waterfall = new waterfallCanvas(c, cw, ch);
      setupRAF();
      waterfall.init();
    }
  }

  return (
    <div className={`App dark-background text-center container-fluid`}>
      {showingSplashScreen === true ? (
        <div id="splash-screen">
          <div className="">
            <SplashScreen
              setShowingSplashScreen={setShowingSplashScreen}
              className
            ></SplashScreen>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`${
              showingSplashScreen ? "hide" : ""
            }  description-anim no-opacity`}
          >
            <nav className="hercules navbar navbar-expand-lg navbar-light bg-dark">
              <div className="container-fluid">
                <a
                  className="navbar-brand padding-left"
                  href=""
                  style={{ color: "#a974f4" }}
                >
                  Cozy Companions
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        id="about-nav"
                        className="nav-link active"
                        style={{ color: "#a974f4" }}
                        aria-current="page"
                        href="#"
                      >
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        id="roadmap-nav"
                        className="nav-link"
                        style={{ color: "#a974f4" }}
                        href="#"
                      >
                        Roadmap
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        id="faq-nav"
                        className="nav-link"
                        style={{ color: "#a974f4" }}
                        href="#"
                      >
                        FAQs
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        id="team-nav"
                        className="nav-link"
                        href="#"
                        style={{ color: "#a974f4" }}
                      >
                        The Team
                      </a>
                    </li>
                  </ul>
                  <form className="d-flex">
                    <span className="form-control me-2 bg-dark no-border">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.tiktok.com/@cozycompanions/"
                      >
                        <span className="mild-horizontal-padding fab fa-2x fa-tiktok padding-bottom"></span>
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://discord.gg/p88Y25CcgS"
                      >
                        <span className="mild-horizontal-padding fab fa-2x fa-discord padding-bottom"></span>
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.twitter.com/cozycompanions/"
                      >
                        <span className="mild-horizontal-padding fab fa-2x fa-twitter padding-bottom"></span>
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/cozy_companions/"
                      >
                        {" "}
                        <span className="mild-horizontal-padding fab fa-2x fa-instagram padding-bottom"></span>
                      </a>
                    </span>
                  </form>
                </div>
              </div>
            </nav>
          </div>
          <div
            className={`${
              showingSplashScreen ? "hide" : ""
            } description-anim no-opacity`}
            style={{ position: "relative" }}
          >
            <div
              className="row no-gutters"
              style={{ display: showingSplashScreen ? "none" : "block" }}
            >
              <div className="hercules orange cozy mx-auto col-6 text-center white">
                Cozy Companions
              </div>
            </div>

            <div
              className="row"
              style={{ display: showingSplashScreen ? "none" : "" }}
            >
              <div className="myriad large col-12 text-center white intro-text">
                10,000 fluffy NFTs living life the Cozy way.
              </div>
            </div>
            <div className="islands-overall">
              <div
                className="row clouds"
                style={{ display: showingSplashScreen ? "none" : "" }}
              >
                <div className="col-4">
                  <img className="cloud-one" src={cloudOne} />
                </div>
                <div className="col-4">
                  <img className="cloud-two" src={cloudTwo} />
                </div>
                <div className="col-4">
                  <img className="cloud-three" src={cloudThree} />
                </div>
              </div>
              <div
                className="row islands"
                style={{ display: showingSplashScreen ? "none" : "" }}
              >
                <div className="col-4">
                  <img className="small-island" src={islandOne} />
                </div>
                <div className="col-4 island-two">
                  <img
                    className="large-island"
                    src={islandTwo}
                    onLoad={getImageInfo}
                  />
                  <canvas id="waterfall" />
                </div>
                <div className="col-4">
                  <img className="small-island" src={islandThree} />
                </div>
              </div>
            </div>
            <div className="info-background" id="about-section">
              <div className="row white large white myriad bold text-center">
                <div className="col-12 orange bold">
                  What are Cozy Companions?
                </div>
              </div>
              <div className="row white white title myriad padding-bottom">
                <div className="col-lg-10 col-12 col-sm-10 col-md-10 mx-auto text-center">
                  Cozy Companions have just entered the metaverse on the
                  Ethereum blockchain, each one exquisitely designed and
                  rendered with{" "}
                  <span>cutting-edge 3-D generative technology</span> to create
                  extremely detailed images in 4K, including Legendary
                  Companions! Cozy Companions will also be fully integrated with
                  next-generation augmented reality, so you can tap into the
                  metaverse with your iPhone camera. Our goal is to create a
                  strong and long-lasting community of people who are passionate
                  about the intersection of NFTs, art, and animals, as part of
                  the proceeds from Cozy Companions will be donated{" "}
                  <span className="">directly to support rescue animals.</span>
                </div>
              </div>

              <div className="row text-center padding-bottom">
                <div className="col-6 mx-auto">
                  {/* this was originally fireballGuy */}
                  <img id="" className="normal-small" src={diceGuy} />
                </div>
              </div>

              <div className="row white white title myriad padding-bottom">
                <div className="col-lg-10 col-12 col-sm-10 col-md-10 mx-auto text-center">
                  Cozy Companions is pushing the boundaries not only in the Web3
                  space, but also in the area of real-world collectibles and
                  utility. Holders will receive voting rights, giveaways,
                  real-life collectibles, rewards from games, exclusive access
                  to parties, and so much more.
                </div>
              </div>

              <div className="row text-center padding-bottom">
                <div className="col-6 mx-auto">
                  {/* this was originally fireballGuy */}
                  <img
                    id="fireball-guy"
                    className="normal"
                    src={website_cozy}
                  />
                </div>
              </div>
            </div>
            <div className="roadmap-background" id="roadmap-section">
              <div className="row init-vertical text-center myriad padding-top">
                <div className="col-6 mx-auto">
                  <div className="bold large orange white">Road Map</div>
                </div>
              </div>
              <div className="row init-vertical text-center myriad">
                <div className="col-lg-10 col-12 col-sm-10 col-md-10 mx-auto">
                  <div className="white title padding-bottom">
                    The entire roadmap of Cozy Companions, from whitelisting to
                    AR integration and more, has been worked out in painstaking
                    detail after consensus from our artists, developers, and
                    design leads.
                  </div>
                </div>
              </div>
              <div className="row init-vertical myriad padding-bottom side-container">
                <img className="side-one" src={propeller} />
                <div className="col-12 padding-bottom">
                  <VerticalTimeline>
                    <VerticalTimelineElement
                      className="vertical-timeline-element"
                      contentStyle={{ background: "black", color: "#fff" }}
                      contentArrowStyle={{ borderRight: "7px solid  #6cff4e" }}
                      date="2023"
                      dateClassName="date-font white bold left-align"
                      iconStyle={{ background: "#3d1573", color: "#6cff4e" }}
                      icon={<FaClipboardList />}
                    >
                      <h3 className="vertical-timeline-element-title bold">
                        Pre-Sale
                      </h3>
                      <p className="timeline-info">
                        All whitelisted users will be able to mint at a price of
                        0.07 ETH.
                      </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      className="vertical-timeline-element"
                      contentStyle={{ background: "black", color: "#fff" }}
                      contentArrowStyle={{ borderRight: "7px solid  #6cff4e" }}
                      date="2023"
                      dateClassName="date-font white bold"
                      iconStyle={{ background: "#3d1573", color: "#6cff4e" }}
                      icon={<FaRegGem />}
                    >
                      <h3 className="vertical-timeline-element-title bold">
                        Public Sale
                      </h3>
                      <p className="timeline-info">
                        The remaining Cozy Companions will be minted at a price
                        of 0.09 ETH.
                      </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      className="vertical-timeline-element"
                      contentStyle={{ background: "black", color: "#fff" }}
                      contentArrowStyle={{ borderRight: "7px solid  #6cff4e" }}
                      date="2023"
                      dateClassName="date-font white bold left-align"
                      iconStyle={{ background: "#3d1573", color: "#6cff4e" }}
                      icon={<FaPaw />}
                    >
                      <h3 className="vertical-timeline-element-title bold">
                        Rescue Animal Donation
                      </h3>
                      <p className="timeline-info">
                        We will donate 10% of all OpenSea royalties in addition
                        to 3% of EVERY Cozy Companion minted to a
                        community-selected animal rescue shelter.
                      </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      className="vertical-timeline-element"
                      contentStyle={{ background: "black", color: "#fff" }}
                      contentArrowStyle={{ borderRight: "7px solid  #6cff4e" }}
                      date="2023"
                      dateClassName="date-font white bold left-align"
                      iconStyle={{ background: "#3d1573", color: "#6cff4e" }}
                      icon={<FaMobileAlt />}
                    >
                      <h3 className="vertical-timeline-element-title bold">
                        AR Integration for Mobile Devices
                      </h3>
                      <p className="timeline-info">
                        You will be able to use AR technology to see Cozy
                        Companions superimposed on your surroundings using your
                        iPhone camera and even take pictures with it in your
                        favorite places!
                      </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      className="vertical-timeline-element--education"
                      contentStyle={{ background: "black", color: "#fff" }}
                      contentArrowStyle={{ borderRight: "7px solid  #6cff4e" }}
                      date="2024"
                      dateClassName="date-font white bold left-align"
                      iconStyle={{ background: "#3d1573", color: "#6cff4e" }}
                      icon={<FaGlobe />}
                    >
                      <h3 className="vertical-timeline-element-title bold">
                        Cozy Companions Collectibles
                      </h3>
                      <p className="timeline-info">
                        Bring your companions to life with some real-world
                        collectibles that we are producing and will give away
                        for free!
                      </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      className="vertical-timeline-element--education"
                      contentStyle={{ background: "black", color: "#fff" }}
                      contentArrowStyle={{ borderRight: "7px solid  #6cff4e" }}
                      date="2024"
                      dateClassName="date-font white bold"
                      iconStyle={{ background: "#3d1573", color: "#6cff4e" }}
                      icon={<FaDog />}
                    >
                      <h3 className="vertical-timeline-element-title bold">
                        Cozy Companion Pets
                      </h3>
                      <p className="timeline-info">
                        Every Cozy Companion holder will be whitelisted for the
                        upcoming Cozy Companions Pet drop. Look out for the
                        opportunity to give your Cozy Companion its very own
                        pet.
                      </p>
                    </VerticalTimelineElement>
                    {/* games of chance */}
                  </VerticalTimeline>
                </div>
              </div>
            </div>

            <div className="faq-background">
              <div className="row init-vertical text-center myriad padding-bottom">
                <div className="col-12 white subtitle medium">
                  Prepare to enter the world of Cozy Companions.
                </div>
              </div>

              <div
                className="row init-vertical text-center myriad"
                id="faq-section"
              >
                <div className="col-6 mx-auto">
                  <div className="bold large orange padding-bottom">FAQs</div>
                </div>
              </div>

              <div className="row text-center myriad padding-bottom  padding-horizontal g-0">
                <div className="col-12">
                  <SimpleAccordion
                    titleContent={"Do Cozy Companions Have Utility?"}
                    bodyContent={
                      "Yes! Owning a Cozy Companion will allow you to receive our utility token from P2E games, give you priority for the upcoming Cozy Companions pets NFT drop, exclusive access to real-life collectibles, and invitations to parties! Owning a Cozy Companion will also allow you to view it in AR on your mobile device after our AR release."
                    }
                  ></SimpleAccordion>
                </div>
              </div>

              <div className="row  text-center myriad padding-bottom padding-horizontal  g-0">
                <div className="col-12">
                  <SimpleAccordion
                    titleContent={"Do Cozy Companions have pets?"}
                    bodyContent={
                      "Yep! We're planning a second launch that will give each Cozy Companion a unique pet -- stay tuned for more!"
                    }
                  ></SimpleAccordion>
                </div>
              </div>

              <div className="row  text-center myriad padding-bottom padding-horizontal  g-0">
                <div className="col-12">
                  <SimpleAccordion
                    titleContent={"How do I get whitelisted?"}
                    bodyContent={
                      "Spots are filling up to mint Cozy Companions at our pre-sale, so hop in our discord! Invite a few friends and have some conversations and we'll make sure that you're on the whitelist :)"
                    }
                  ></SimpleAccordion>
                </div>
              </div>
            </div>
            <div className="team-background padding-bottom" id="team-section">
              <div className="row init-vertical text-center myriad">
                <div className="col-6 mx-auto">
                  <div className="bold large orange padding-bottom">
                    The Team
                  </div>
                </div>
              </div>
              <div className="row init-vertical text-center myriad">
                <div className="col-6 mx-auto">
                  <div className="bold title white padding-bottom">
                    PlutoBaggins
                  </div>
                  <img src={plutoBaggins} className="team-image" />
                </div>
                <div className="col-6 mx-auto">
                  <div className="bold title white padding-bottom">
                    Armatillz
                  </div>
                  <img src={armatillz} className="team-image" />
                </div>
              </div>
              <div className="row init-vertical text-center myriad">
                <div className="col-6 mx-auto">
                  <div className="medium white padding-bottom">
                    Design lead. Marketing, artistic director, NFT expert.
                  </div>
                </div>
                <div className="col-6 mx-auto">
                  <div className="medium white padding-bottom">
                    Tech lead. Website and blockchain dev. AR expert.
                  </div>
                </div>
              </div>
              <div className="col-6 mx-auto myriad">
                <div className="medium white padding-bottom">
                  And many more talented and thoughtful members of the Cozy
                  Family!
                </div>
              </div>
            </div>
            <div className="socials-background">
              <div className="row text-center myriad">
                <div className="col-6 mx-auto">
                  <div className="bold large white padding-bottom"></div>
                </div>
              </div>
              <div className="row padding-bottom-large">
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/@cozycompanions/"
                  >
                    <span className="fab fa-4x fa-tiktok padding-bottom"></span>
                  </a>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://discord.gg/p88Y25CcgS"
                  >
                    <span className="fab fa-4x fa-discord padding-bottom"></span>
                  </a>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.twitter.com/cozycompanions/"
                  >
                    <span className="fab fa-4x fa-twitter padding-bottom"></span>
                  </a>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/cozy_companions/"
                  >
                    {" "}
                    <span className="fab fa-4x fa-instagram padding-bottom"></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-background padding-bottom">
              <div className="row">
                <div className="col-12 white myriad large">
                  Cozy Companions, 2023
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default App;
